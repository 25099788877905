
  /* File: DataSelectionPage.css */

/* Ensure html and body cover the full height */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .data-selection-page {
    width: 100%;
    padding: 20px;
    background-color: #f7f7f7;
  }
  
  .page-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .category-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 20px; /* Space between grid items */
  }
  
  .category-block {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .category-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: #0070f3;
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
  }
  
  .checkbox-input {
    margin-right: 10px;
  }
  
  .checkbox-label {
    font-size: 16px;
    color: #333;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background-color: #0070f3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .submit-button:hover {
    background-color: #005bb5;
  }