/* Reset default margins and paddings */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

/* Ensure the body fills the viewport */
body {
    min-height: 100%;
}

/* Background container */
.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    background: #F0F4FC; /* Couleur de fond neutre */
}

/* Frosted glass effect */
.frosted-glass {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(80px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

/* Blob animation */
@keyframes blobAnimation {
    0%, 100% {
        border-radius: 60% 40% 60% 40% / 60% 60% 40% 40%;
        transform: translate(0, 0);
    }
    20% {
        border-radius: 55% 45% 50% 50% / 55% 65% 35% 45%;
        transform: translate(3vw, -2vh) rotate(1deg);
    }
    40% {
        border-radius: 50% 50% 55% 45% / 50% 70% 30% 50%;
        transform: translate(-2vw, 3vh) rotate(-1deg);
    }
    60% {
        border-radius: 65% 35% 45% 55% / 60% 40% 60% 40%;
        transform: translate(2vw, -3vh) rotate(2deg);
    }
    80% {
        border-radius: 55% 45% 60% 40% / 55% 75% 25% 45%;
        transform: translate(-3vw, 2vh) rotate(-2deg);
    }
}

/* Blob shapes */
.blob {
    position: absolute;
    width: 300px; /* Taille réduite pour des formes plus subtiles */
    height: 300px;
    opacity: 0.3; /* Opacité réduite pour une présence plus discrète */
    animation: blobAnimation infinite;
    animation-timing-function: ease-in-out;
    filter: blur(3px); /* Douceur pour intégrer les blobs au fond */
}

/* Palette de couleurs modernes */
:root {
    --color-1: linear-gradient(135deg, #4A90E2, #50C9C3); /* Bleu vif à Cyan */
    --color-2: linear-gradient(135deg, #50C9C3, #A18CD1); /* Cyan à Violet doux */
    --color-3: linear-gradient(135deg, #A18CD1, #4776E6); /* Violet à Bleu moyen */
    --color-4: linear-gradient(135deg, #4776E6, #4A90E2); /* Bleu moyen à Bleu vif */
    --color-5: linear-gradient(135deg, #6A5ACD, #7B68EE); /* Bleu violet */
}

/* Blob 1 */
.blob-1 {
    top: 10%;
    left: 15%;
    animation-duration: 80s;
    animation-delay: -20s;
    background: var(--color-1);
}

/* Blob 2 */
.blob-2 {
    top: 5%;
    left: 50%;
    animation-duration: 90s;
    animation-delay: -25s;
    background: var(--color-2);
}

/* Blob 3 */
.blob-3 {
    top: 40%;
    left: 25%;
    animation-duration: 100s;
    animation-delay: -30s;
    background: var(--color-3);
}

/* Blob 4 */
.blob-4 {
    top: 35%;
    left: 65%;
    animation-duration: 110s;
    animation-delay: -35s;
    background: var(--color-4);
}

/* Blob 5 (Optionnel) */
.blob-5 {
    top: 20%;
    left: 40%;
    animation-duration: 120s;
    animation-delay: -40s;
    background: var(--color-5);
    width: 250px;
    height: 250px;
    opacity: 0.2; /* Opacité encore plus réduite */
    filter: blur(4px); /* Plus flou pour une présence très subtile */
}

/* Ajustement des transformations initiales pour une disposition harmonieuse */
.blob-1,
.blob-2,
.blob-3,
.blob-4,
.blob-5 {
    transform: translate(-50%, -50%);
}



